import React from 'react';

import businessInfo from '../site.config';

function Footer() {
  return (
    <div className='Footer'>
      <div className="footer-nav">
        <h2 className='footer-brand has-text-primary has-text-weight-semibold'>{businessInfo.name}</h2>
        <h2 className='footer-contact'>Questions? Contact us at <span className='has-text-primary has-text-weight-semibold'>{businessInfo.phone}</span></h2>
      </div>
      <p>Icons made by Eucalyp from www.flaticon.com.</p>
    </div>
  )
}

export default Footer;