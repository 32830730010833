import React from 'react';

import businessInfo from '../site.config';

function AboutUs() {
  return (
    <div className='about-us'>
      <div className='about-us-container'>
        <div className='about-us-content-block'>
          <div className='about-us-text-block'>
            <h3 className='subtitle has-text-white'>About Our Company</h3>
            <p className='about-description'>
               
              We know that owners may want to sell land for a variety of reasons, and we 
              know that this can be difficult to accomplish on your own. It’s our goal 
              to make this process fair and accessible for everyone.  We will pay cash 
              for any kind of acreage and land usage. These offers are cash, fast, and 
              come with no obligation. You pay no commission on this sale, and we draw 
              up all the paperwork. Interested in chatting with us? Fill out our brief 
              form to get you cash offer right away or give us a call!

              <span className='signature'>
              Contact Us
              <br/>
              {businessInfo.phone}
              <br />
              {businessInfo.email}
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AboutUs;
