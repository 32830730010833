import React, { useState } from 'react';
import './App.scss';

import Hero from './components/Hero';
import HowItWorks from './components/HowItWorks';
import TheSell from './components/TheSell';
import AboutUs from './components/AboutUs';
import Notification from './components/Notification';
import Footer from './components/Footer';

// import FeatureRight from './components/FeatureRight';
// import FeatureLeft from './components/FeatureLeft';
// import FAQ from './components/FAQ';

function App() {
  const [ isNotificationShowing, setIsNotificationShowing] = useState(false);

  return (
    <div className="App">
      <Hero setIsNotificationShowing={setIsNotificationShowing} />
      {/* <FeatureRight /> */}
      {/* <FeatureLeft /> */}
      <HowItWorks />
      <TheSell setIsNotificationShowing={setIsNotificationShowing} />
      {/* <FAQ /> */}
      <AboutUs />
      <Notification isNotificationShowing={isNotificationShowing} setIsNotificationShowing={setIsNotificationShowing} />
      <Footer />
    </div>
  );
}

export default App;
