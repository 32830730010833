import React from 'react';

function Notification(props) {
  const hideNotification = () => {
    props.setIsNotificationShowing(false);
  }

  return (
    <div className={`notification is-success ${!props.isNotificationShowing && 'is-hidden'}`}>
      <button className='delete' onClick={hideNotification}></button>
      Thank you for subscribing!
    </div>
  )
}

export default Notification;
