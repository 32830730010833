import React from 'react';
import jsonp from 'jsonp';
import useInputState from '../hooks/useInputState';
// import captureClientEmail from '../../functions/firebase-cloud-functions';

import businessInfo from '../site.config'

// import '../styles/EmailCapture.scss';

/**
 * In order for this signup form to work, go into MailChimp and create
 * a new embedded Sign Up Form for the desired audience. 
 * 
 * Under where it says "Copy/paste onto your site", copy the action URL
 * and paste it into the action variable below under handleSubmit.
 *
 * You may have to edit the Audience in MailChimp and add new Merge Fields
 * for COUNTY, PARCELNUM, and FSOURCE for the code below to work properly.
 */

function EmailCapture(props) {
  const [ userEmail, handleEmailChange, resetEmail ] = useInputState('');
  const [ userName, handleNameChange, resetName ] = useInputState('');
  const [ userPhone, handlePhoneChange, resetPhone ] = useInputState('');
  const [ userCounty, handleCountyChange, resetCounty ] = useInputState('');
  const [ userParcelNumber, handleParcelNumberChange, resetParcelNumber ] = useInputState('');

  const handleSubmit = (evt) => {
    evt.preventDefault();
    const email = userEmail;
    const action = 'https://maukainnovations.us18.list-manage.com/subscribe/post?u=60642a34b12513ae5ffa87c03&amp;id=c48704b2cb';
    const fields = [
      { name: 'EMAIL', 'EMAIL': userEmail }, 
      { name: 'FNAME', 'FNAME': userName },
      { name: 'PHONE', 'PHONE': userPhone },
      { name: 'COUNTY', 'COUNTY': userCounty },
      { name: 'PARCELNUM', 'PARCELNUM': userParcelNumber },
      { name: 'FSOURCE', 'FSOURCE': `${businessInfo.name}` }
    ];

    const values = fields.map(field => {
      return `${field.name}=${encodeURIComponent(field[field.name])}`;
    }).join("&");
    
    const path = `${action}&${values}`;
    const url = path.replace('/post?', '/post-json?');
    const regex = /^([\w_.\-+])+@([\w-]+\.)+([\w]{2,10})+$/;
    (!regex.test(email)) ? console.log('Empty field - Err') : sendData(url);

    props.setIsNotificationShowing(true);
    resetAllInputFields();
    // redirectToGoogleForm();
  };

  const sendData = (url) => {
    // this.setState({ status: "sending" });
    jsonp(url, { param: "c" }, (err, data) => {
      // if (data.msg.includes("already subscribed")) {
      //   this.setState({ status: 'duplicate' });
      // } else if (err) {
      //   this.setState({ status: 'error' });
      // } else if (data.result !== 'success') {
      //   this.setState({ status: 'error' });
      // } else {
      //   this.setState({ status: 'success' });
      // };
      console.log(err);
      console.log(data);
    });
  }

  const resetAllInputFields = () => {
    resetEmail();
    resetName();
    resetPhone();
    resetCounty();
    resetParcelNumber();
  };

  // const redirectToGoogleForm = () => {
  //   const googleFormUrl = 'https://forms.gle/CujGFVYfruQnuQLw6';
  //   window.location = googleFormUrl;
  // };

  return (
    <>
      <div className='capture columns'>
        <div className='column capture-features is-7-tablet is-8-desktop'>
          <article className='media'>
            <figure className='media-left'>
              <div className='image'>
                <img src='https://landleads.reiconversion.com/wp-content/uploads/elementor/thumbs/sell-your-land-property-point-icon-o8pbzy90kckyn9a1sg4o73knb2vf5mglkh10jbx5wy.png' alt='Ribbon Icon' />
              </div>
            </figure>
            <div className='media-content'>
              <div className='content'>
                <h2 className='subtitle is-size-4 has-text-primary'>
                  Get a Fair Cash Offer
                </h2>
              </div>
            </div>
          </article>
          <article className='media'>
            <figure className='media-left'>
              <div className='image'>
                <img src='https://landleads.reiconversion.com/wp-content/uploads/elementor/thumbs/sell-your-land-property-point-icon-o8pbzy90kckyn9a1sg4o73knb2vf5mglkh10jbx5wy.png' alt='Ribbon Icon' />
              </div>
            </figure>
            <div className='media-content'>
              <div className='content'>
                <h2 className='subtitle is-size-4 has-text-primary'>
                  We Will Buy Land in Any Condition 
                </h2>
              </div>
            </div>
          </article>
          <article className='media'>
            <figure className='media-left'>
              <div className='image'>
                <img src='https://landleads.reiconversion.com/wp-content/uploads/elementor/thumbs/sell-your-land-property-point-icon-o8pbzy90kckyn9a1sg4o73knb2vf5mglkh10jbx5wy.png' alt='Ribbon Icon' />
              </div>
            </figure>
            <div className='media-content'>
              <div className='content'>
                <h2 className='subtitle is-size-4 has-text-primary'>
                  We'll Take Care of Paperwork and Closing
                </h2>
              </div>
            </div>
          </article>

          <div className='start-here'>
            <h3>Start here by filling out this form!</h3>
            <span className='icon email-capture-arrow'>
              <i className='fas fa-long-arrow-alt-right' />
            </span>
          </div>
        </div>
        <div className='column capture-form has-text-left'>
          <h2>Start Here</h2>
          <p>To Get Your Cash Offer!</p>
          <form className='field-group' onSubmit={handleSubmit}>
            <div className='field'>
              <div className='control has-icons-left'>
                <input className='input is-medium' type='text' name='FNAME' required value={userName} onChange={handleNameChange} placeholder='Full Name' />
                <span className='icon is-left'>
                  <i className='fas fa-user' />
                </span>
              </div>
            </div>
            <div className='field'>
              <div className='control has-icons-left'>
                <input className='input is-medium' type='text' name='PHONE' required value={userPhone} onChange={handlePhoneChange} placeholder='Phone Number' />
                <span className='icon is-left'>
                  <i className='fas fa-phone-alt' />
                </span>
              </div>
            </div>
            <div className='field'>
              <div className='control has-icons-left'>
                <input className='input is-medium' type='text' name='EMAIL' required value={userEmail} onChange={handleEmailChange} placeholder='Email Address' />
                <span className='icon is-left'>
                  <i className='fas fa-envelope' />
                </span>
              </div>
            </div>
            <div className='field'>
              <div className='control has-icons-left'>
                <input className='input is-medium' type='text' name='COUNTY' required value={userCounty} onChange={handleCountyChange} placeholder="County" />
                <span className='icon is-left'>
                  <i className='fas fa-tree' />
                </span>
              </div>
            </div>
            <div className='field'>
              <div className='control has-icons-left'>
                <input className='input is-medium' type='text' name='PARCELNUM' required value={userParcelNumber} onChange={handleParcelNumberChange} placeholder="Parcel Number" />
                <span className='icon is-left'>
                  <i className='fas fa-map-marked-alt' />
                </span>
              </div>
            </div>
            <div className='field'>
              <div className='control'>
                <button type='submit' className='button capture-button is-large is-fullwidth'>Get Your Cash Offer</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}

export default EmailCapture;
