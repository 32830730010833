import React from 'react';
import EmailCapture from './EmailCapture';

import businessInfo from '../site.config';

// import '../styles/Hero.scss';

function Hero(props) {
    return (
        <section className='hero is-large flex'>
          <div className='hero-head'>
            <nav className='navbar'>
              <div className='container'>
                <div className='navbar-brand'>
                  <div className='level'>
                    <div className='level-left'>
                      <div className='level-item'>
                        <div className='navbar-item has-text-primary navbar-brand-text' href='/'>
                          {businessInfo.name}
                        </div>
                      </div>
                    </div>
                    <div className='level-right'>
                      <div className='level-item'>
                        <div className='navbar-item navbar-contact is-size-4-tablet'>
                          Call Us Today At &nbsp;
                          <p className='has-text-primary has-text-weight-semibold navbar-number' href='/'>
                            {businessInfo.phone}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  
                </div>
                <div className='navbar-menu'>
                  <div className='navbar-end'>
                    
                  </div>
                </div>
              </div>
            </nav>
          </div>

          <div className='hero-body has-text-centered' id='hero-body'>
            <h1 className='title is-size-1 has-text-primary'>
              Looking to Sell Your Land for <span>Quick Cash</span>?
            </h1>
            <p className='subtitle is-size-3 has-text-white has-text-weight-semibold'>We Will Pay Cash for Any Acreage or Usage!</p>

            <EmailCapture setIsNotificationShowing={props.setIsNotificationShowing} />
            
            {/* <div className='video-container'>
              <iframe title='Video' id='video' src="https://www.youtube.com/embed/yIryZPQs0LM" frameborder="0" allow="accelerometer; encrypted-media; gyroscope; picture-in-picture" allowfullscreen />
            </div> */}
          </div>
        </section>
    )
}

export default Hero;
