import React from 'react';
import EmailCapture from './EmailCapture';
// import '../styles/TheSell.scss';

function TheSell(props) {
  return (
    <div className='the-sell'>
      <EmailCapture setIsNotificationShowing={props.setIsNotificationShowing} />
    </div>
  )
}

export default TheSell;
